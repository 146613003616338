import * as React from "react";
import { SxProps, Theme } from "@mui/material/styles";
import { Typography } from "@mui/material";

interface TransactionIdProps {
    transactionId: number;
    sx?: SxProps<Theme>;
}

const Currency = (props: TransactionIdProps) => {
    return (
        <Typography component="span" sx={props.sx}>
            {props.transactionId}
        </Typography>
    );
};

export default Currency;
