import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import LogoPage from "./LogoPage";
import { logout } from "shared/auth/MSALAuth";
import companyService from "shared/services/CompanyService";
import { useAuthContext } from "modules/Auth/AuthProvider";

const AcceptCompanyInvite = () => {
    const { getCredentials } = useAuthContext();
    const params = useParams();
    const [messageToDisplay, setMessageToDisplay] = useState("");

    useEffect(() => {
        validateInviteUrl();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateInviteUrl = async () => {
        if (params.inviteId) {
            try {
                await companyService.acceptInviteCompanyUser(params.inviteId);
                setMessageToDisplay("Accepted, redirecting...");
                getCredentials();
            } catch (e) {
                setMessageToDisplay(
                    "Error accepting invitation, Logout and try again. Please contact to your company administrator if it continues.",
                );
            }
        } else {
            setMessageToDisplay("Not found");
        }
    };

    return (
        <>
            <div>
                <LogoPage message={messageToDisplay} />
            </div>
            <center>
                <h1>
                    <Button variant="contained" size="large" onClick={logout}>
                        Logout
                    </Button>
                </h1>
            </center>
        </>
    );
};

export default AcceptCompanyInvite;
