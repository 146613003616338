import { z } from "zod";
import { InterfaceToZod } from "./UtilsTypes";
import {
    CheckoutClickAgreement,
    ITransactionDetails,
    ReceiptTransaction,
    RefundCatalogItem,
    RefundCatalogItemTransaction,
    RefundReservation,
    RefundTransaction,
    RentalInsuranceLinkUrl,
    RentalInsuranceLinkUrls,
    SignWaiverLink,
    TransactionDeliveryPickup,
    TransactionItem,
    TransactionReservation,
    TransactionTicket,
    RefundedTransaction,
} from "./Transaction";
import { TransactionReservationDetailSchema } from "./Reservation.schema";
import { IPaymentGatewayResult, IRefundResult } from "./Reservation";
import { IContactInfo } from "./ContactInfo";
import { AssignedSeatsCategorySchema } from "./Seats.schema";
import { BillingInfo } from "./Cart";
import { DocumentUpload } from "./Event";

export const RefundReservationSchema = z.object<InterfaceToZod<RefundReservation>>({
    reservationId: z.number().nullable(),
    refundAmount: z.number(),
    refundServiceCharge: z.number(),
    refundTax: z.number(),
    appliedCardCode: z.string().nullable().optional(),
    ticketEdits: z.array(TransactionReservationDetailSchema).nullable().optional(),
    description: z.string(),
    catalogId: z.number(),
});
export const RefundCatalogItemTransactionSchema = z.object<
    InterfaceToZod<RefundCatalogItemTransaction>
>({
    quantity: z.number(),
    unitPrice: z.number(),
});
export const RefundCatalogItemSchema = z.object<InterfaceToZod<RefundCatalogItem>>({
    catalogId: z.number(),
    // refundQuantity: z.number(),
    reservationIdAddOn: z.number(),
    unitPrice: z.number(),
    refundServiceCharge: z.number(),
    refundTax: z.number(),
    description: z.string(),
    transactions: z.array(RefundCatalogItemTransactionSchema),
});

export const RefundTransactionSchema = z.object<InterfaceToZod<RefundTransaction>>({
    transactionId: z.number(),
    maxTotalRefund: z.number(),
    refundReservations: z.array(RefundReservationSchema).nullable(),
    refundCatalogItems: z.array(RefundCatalogItemSchema).nullable(),
    refundAmount: z.number(),
    refundTax: z.number(),
});

export const RefundedTransactionSchema = z.object<InterfaceToZod<RefundedTransaction>>({
    transactionId: z.number(),
    refund: z.number(),
    refundServiceCharge: z.number(),
});

export const TransactionTicketSchema = z.object<InterfaceToZod<TransactionTicket>>({
    rateId: z.number(),
    tickets: z.number(),
    rate: z.number().nullable().optional(),
    description: z.string().nullable().optional(),
});

export const RentalInsuranceLinkUrlSchema = z.object<InterfaceToZod<RentalInsuranceLinkUrl>>({
    url: z.string(),
    linkName: z.string(),
});

export const RentalInsuranceLinkUrlsSchema = z.object<InterfaceToZod<RentalInsuranceLinkUrls>>({
    required: z.array(RentalInsuranceLinkUrlSchema),
    optional: z.array(RentalInsuranceLinkUrlSchema),
    hasLinks: z.boolean(),
});

export const TransactionDeliveryPickupSchema = z.object<InterfaceToZod<TransactionDeliveryPickup>>({
    address: z.string().nullable().optional(),
    lat: z.number().nullable().optional(),
    lon: z.number().nullable().optional(),
});
export const DocumentUploadSchema = z.object<InterfaceToZod<DocumentUpload>>({
    type: z.number(),
    description: z.string(),
});
export const TransactionReservationSchema = z.object<InterfaceToZod<TransactionReservation>>({
    transactionItemID: z.number(),
    description: z.string().nullable(),
    companyID: z.number(),
    eventID: z.number(),
    reservationID: z.number(),
    origTransactionItemID: z.number(),
    wholesale: z.number(),
    origUnitPrice: z.number(),
    unitPrice: z.number(),
    tax: z.number(),
    amountDue: z.number(),
    taxRate: z.number(),
    serviceCharge: z.number(),
    ebmServiceCharge: z.number(),
    refund: z.number(),
    refundServiceCharge: z.number(),
    offerTripInsurance: z.boolean(),
    deposit: z.boolean(),
    companyName: z.string().nullable(),
    companyPhone: z.string().nullable(),
    companyEmail: z.string().nullable(),
    address: z.string().nullable(),
    map_URL: z.string().nullable().optional(),
    reservation_Info: z.string().nullable().optional(),
    restrictions: z.string().nullable(),
    firstName: z.string().nullable().optional(),
    lastName: z.string().nullable().optional(),
    eventDateTime: z.string(),
    startTime: z.string(),
    tickets: z.array(TransactionTicketSchema),
    transactionReservationDetails: z.array(TransactionTicketSchema),
    hasUploadDocuments: z.boolean(),
    uploadDocumentsURl: z.string().nullable(),
    documentUploadList: z.array(DocumentUploadSchema).nullable(),
    rentalInsuranceLinkUrls: RentalInsuranceLinkUrlsSchema,
    baseUrl: z.string().nullable().optional(),
    seatAssignments: z.array(AssignedSeatsCategorySchema),
    imgURL: z.string().nullable().optional(),
    deliveryPickup: TransactionDeliveryPickupSchema,
    insuranceRequestLink: z.string().nullable(),
});

export const CheckoutClickAgreementSchema = z.object<InterfaceToZod<CheckoutClickAgreement>>({
    agreement: z.string().nullable(),
    agree: z.boolean(),
});

export const SignWaiverLinkSchema = z.object<InterfaceToZod<SignWaiverLink>>({
    url: z.string(),
    eventName: z.string(),
    eventDate: z.string(),
});

export const BillingInfoSchema = z.object<InterfaceToZod<BillingInfo>>({
    firstName: z.string().nullable(),
    lastName: z.string().nullable(),
    phone: z.string().nullable(),
    email: z.string().nullable(),
    address: z.string().nullable(),
    address2: z.string().nullable(),
    city: z.string().nullable(),
    state: z.string().nullable(),
    zip: z.string().nullable(),
    country: z.string().nullable(),
});

export const ContactInfoSchema = z.object<InterfaceToZod<IContactInfo>>({
    lastName: z.string().nullable(),
    firstName: z.string().nullable(),
    phone: z.string().nullable(),
    email: z.string().nullable(),
});

export const TransactionItemSchema = z.object<InterfaceToZod<TransactionItem>>({
    transactionItemID: z.number(),
    description: z.string().nullable(),
    itemCode: z.string().nullable(),
    requiresShipping: z.boolean(),
    companyID: z.number(),
    catalogID: z.number(),
    reservationID: z.number(),
    reservationIDAddOn: z.number(),
    quantity: z.number(),
    unitPrice: z.number(),
    origUnitPrice: z.number(),
    wholesale: z.number(),
    tax: z.number(),
    refund: z.number(),
    refundServiceCharge: z.number(),
    serviceCharge: z.number(),
    ebmServiceCharge: z.number(),
    total: z.number(),
    taxRate: z.number(),
});

export const RefundReceiptSchema = z.object<InterfaceToZod<ReceiptTransaction>>({
    reservations: z.array(TransactionReservationSchema).nullable(),
    subTotal: z.number(),
    serviceCharge: z.number(),
    tax: z.number(),
    maxRefund: z.number(),
    allowVoid: z.boolean(),
    companyEntry: z.boolean(),
    asEmail: z.boolean(),
    receiptHeader: z.string().nullable(),
    sellerEmail: z.string().nullable(),
    signaturePoints: z.string().nullable(),
    obscuredTransaction: z.string().nullable(),
    refundTransactions: z.array(RefundedTransactionSchema).nullable(),
    originalTransactionId: z.number(),
    clickAgreements: z.array(CheckoutClickAgreementSchema).nullable(),
    signWaiverLinks: z.array(SignWaiverLinkSchema).nullable(),
    email: z.string().nullable(),
    billingInfo: BillingInfoSchema.nullable(),
    transactionID: z.number(),
    transactionDate: z.string(),
    shippingInfo: ContactInfoSchema.nullable(),
    card_Type: z.string().nullable(),
    card_Last_Digits: z.string().nullable(),
    card_Authorization: z.string().nullable(),
    card_TransactionID: z.string().nullable(),
    cartCompanyId: z.number(),
    cardProcessorID: z.number().nullable(),
    cartCompanySlug: z.string().nullable(),
    items: z.array(TransactionItemSchema),
    hasSavedPayment: z.boolean(),
});

export const ReceiptSchema = z.object<InterfaceToZod<ITransactionDetails>>({
    receipt: RefundReceiptSchema,
    refunds: RefundTransactionSchema,
});

export const PaymentGatewayResultSchema = z.object<InterfaceToZod<IPaymentGatewayResult>>({
    success: z.boolean(),
    errors: z.array(z.string()),
    transactionReference: z.string().nullable(),
    authorization: z.string().nullable(),
    cardLastDigits: z.string().nullable(),
    brand: z.string().nullable(),
    rechargeToken: z.string().nullable(),
    amount: z.number(),
});

export const RefundResultSchema = z.object<InterfaceToZod<IRefundResult>>({
    transactionId: z.number(),
    reservationId: z.number(),
    paymentGatewayResult: PaymentGatewayResultSchema,
});
